  <br>
    <div class="row card justify-content-center" style="padding: 0px; border: 0px;">

      <div id="alerta-mensagem-piloto">
        <button style="padding: 10px;" id="botao" type="button" class="close" (click)="fechaAlerta('alerta-mensagem-piloto')" >&times;</button>
          <div class="mensagemPiloto">
          <br>
          ATENÇÃO
          <br><br>
          O Validador de Leiautes está em fase de desenvolvimento. Caso queira nos enviar
          <br>
          sugestões, aponte a câmera do seu celular no QR Code, ou acesse o link abaixo.
          <br><br>
          <img src="./assets/imagens/QRlinkPesquisa.png" alt="Trulli" style="width:15%">
          <br><br>
          <a class="mensagemPiloto" href="https://bit.ly/3p0iqA8" target="_blank">https://bit.ly/3p0iqA8</a>
          <br><br>
          </div>
      </div>
    </div>


