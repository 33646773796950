import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TopoComponent} from './components/topo/topo.component';
import {RodapeComponent} from './components/rodape/rodape.component';
import {LoginComponent} from './components/login/login.component';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './components/home/home.component';
import {IntegerPipe} from './pipes/integer.pipe';
import {IntegerDirective} from './directives/integer.directive';
import {LoginModalComponent} from './components/login-modal/login-modal';
import {ErroCarregamentoComponent} from './components/erro-carregamento/erro-carregamento.component';
import {UserIdleModule} from '../modules/user-idle-module/user-idle.module';
import {CookieModule} from 'ngx-cookie';
import {ConfirmacaoComponent} from './components/modais/confirmacao/confirmacao.component';
import {DataTablesModule} from 'angular-datatables';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FormsComponent } from './components/forms/forms.component';
import { SurveyComponent } from './components/survey/survey.component';
import { FaqExternoComponent } from './components/faq/faq-externo/faq-externo.component';
import { FaqComponent } from './components/faq/faq-interno/faq.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CookieModule.forRoot(),
    UserIdleModule.forRoot({idle: 1500, timeout: 30, ping: 1680}),
    DataTablesModule
  ],
  declarations: [
    TopoComponent,
    RodapeComponent,
    LoginComponent,
    HomeComponent,
    ConfirmacaoComponent,
    IntegerDirective,
    IntegerPipe,
    LoginModalComponent,
    ErroCarregamentoComponent,
    FormsComponent,
    SurveyComponent,
    FaqComponent,
    FaqExternoComponent
  ],
  exports: [
    TopoComponent,
    RodapeComponent,
    LoginComponent,
    LoginModalComponent,
    ConfirmacaoComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IntegerDirective,
    DataTablesModule
  ],
  providers: [IntegerPipe]
})

export class SharedModule {
}
