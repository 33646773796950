import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../global.service';
import {RespostaAnalise} from '../../model/models';

@Injectable({
  providedIn: 'root'
})
export class ValidadorService {

  constructor(private http: HttpClient, private globalService: GlobalService) {
  }

  obterLeiautes(): Observable<Array<any>> {
    let url: string;
    url = this.globalService.getConfiguracao()?.api_validador + '/v2/listamapas';
    return this.http.get<Array<any>>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    });
  }

  validarArquivo(leiaute: string, formData: FormData): Observable<RespostaAnalise> {
    let url: string;
    url = this.globalService.getConfiguracao().api_validador + '/v2/validar?leiaute=' + leiaute;
    return this.http.post<any>(url, formData);
  }
}
