import {ModuleWithProviders, NgModule} from '@angular/core';
import {UserIdleConfig} from './user-idle-config';

/*
modulo que contem o servico de verificacao de atividade do usuario.
modificacao do projeto https://www.npmjs.com/package/angular-user-idle
permitindo o controle total sobre os timers, bem como atualizacao para o angular 9
*/

@NgModule({
  imports: []
})
export class UserIdleModule {
  static forRoot(config: UserIdleConfig): ModuleWithProviders<any> {
    return {
      ngModule: UserIdleModule,
      providers: [
        {provide: UserIdleConfig, useValue: config}
      ]
    };
  }
}
