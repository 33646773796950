import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erro-carregamento',
  templateUrl: './erro-carregamento.component.html',
  styleUrls: ['./erro-carregamento.component.css']
})
export class ErroCarregamentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  reloadPage() {
    window.location.assign('');
  }
}
