import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CustomHttpInterceptor } from './shared/interceptors/custom-http-interceptor';
import { BlockUIModule } from 'ng-block-ui';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BlockUIModule.forRoot(),
    HttpClientModule,
    NgxMatomoTrackerModule.forRoot({
      siteId: 597,
      trackerUrl: '//webanalytics.intranet.bb.com.br/',
    }),
    NgxMatomoRouterModule,
  ],
  exports: [],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
