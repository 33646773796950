import { Injectable } from '@angular/core';

/*
Servico utilizado para comunicacao de dados entre os componentes
*/

@Injectable({
  providedIn: 'root'
})
export class DataTransportService {

  public storage: any;

  constructor() { }
}
