import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Observer} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  prefixo = '';
  titulo = '';
  mensagem = '';
  fechar = '';
  enableCopy = false;
  dadosGeradosObserver: Observer<any> = null;

  constructor(private router: Router) {
  }

  exibirMensagem(titulo: string, mensagem: string, fechar: string, enableCopy?: boolean): void {
    this.titulo = titulo;
    this.mensagem = mensagem;
    this.fechar = fechar;
    if (enableCopy) {
      this.enableCopy = true;
    }

    this.router.navigate(['',
      {outlets: {modal: null}}]
    );

    // const options = {backdrop: 'static'};
    // $('#modal').modal('show');
  }

  abrirPaginaModal(caminho: string, parametros: any, queryParametros: any, titulo: string, fechar: string): Observable<any> {
    const subscription: Observable<any> =  new Observable(observer => this.dadosGeradosObserver = observer);

    this.titulo = titulo;
    this.mensagem = '';
    this.fechar = fechar;
    // {outlets: {modal: [caminho, parametros]}}],
    // {queryParams: queryParametros}
    this.router.navigate([
      {outlets: {modal: [caminho]}}], parametros);

    // const options = {backdrop: 'static'};
    // $('#modal').modal('show');

    return subscription;
  }

  fecharPaginaModal(): void {
    this.fechar = '';
    this.router.navigate([this.prefixo, {outlets: {modal: null}}]);
    if (this.dadosGeradosObserver != null) {
      this.dadosGeradosObserver.complete();
      this.dadosGeradosObserver = null;
    }
    // $('#modal').modal('hide');
    $('.modal-backdrop').each(function() {
      $(this).remove();
    });
  }

  incluirDadosGerados(dados: any): void {

    if (this.dadosGeradosObserver != null) {
      this.dadosGeradosObserver.next(dados);
    }
  }


  copyText(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.mensagem;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
