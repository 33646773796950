import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../../service/modal.service';

@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.css']
})
export class ConfirmacaoComponent implements OnInit {

  message: string;

  public constructor(private route: ActivatedRoute, private modalService: ModalService) {
    this.route.queryParams?.subscribe(params => {
      this.message = params.mensagem;
    });
  }

  ngOnInit(): void {
  }

  confirma(): void {
    this.modalService.incluirDadosGerados(true);
    this.modalService.fecharPaginaModal();
  }

  naoConfima(): void {
    this.modalService.incluirDadosGerados(false);
    this.modalService.fecharPaginaModal();
  }
}
