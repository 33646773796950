import { FaqExternoComponent } from './shared/components/faq/faq-externo/faq-externo.component';
import { FaqComponent } from './shared/components/faq/faq-interno/faq.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './shared/components/home/home.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ErroCarregamentoComponent } from './shared/components/erro-carregamento/erro-carregamento.component';
import { LoginGuard } from './shared/guards/login-guard';
import { RoleGuard } from './shared/guards/role-guard';

const routes: Routes = [
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'faq-externo',
    component: FaqExternoComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      expectedRole: 'sia:usuario',
      tipoUsuario: 3,
    },
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      expectedRole: 'sia:usuario',
      tipoUsuario: 3,
    },
  },
  {
    path: 'erro-carregamento',
    component: ErroCarregamentoComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
