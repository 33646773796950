import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../../service/global.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {DataTransportService} from '../../service/data-transport.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-topo',
    templateUrl: './topo.component.html',
    styleUrls: ['./topo.component.css']
})
export class TopoComponent implements OnInit {

    isLogedSubscription: Subscription;
    isLoged: boolean;

    constructor(public globalService: GlobalService, public router: Router, public dataTransportService: DataTransportService) {
    }

    ngOnInit(): void {
        this.isLogedSubscription = this.globalService.isLogedObservable().subscribe(retorno => {
            this.isLoged = retorno;
        });
        $(() => {
            $(document).on('click',
                (event) => {
                    const target = $(event.target);
                    const mobileMenuOpen = $('.navbar-collapse').hasClass('show');
                    if (mobileMenuOpen === true && !target.hasClass('navbar-toggler')) {
                        $('button.navbar-toggler').trigger('click');
                    }
                }
            );
        });
    }

    selecionaMenu(menuSelecionado: string): void {
        switch (menuSelecionado) {
            case 'logout': {
                this.globalService.logoutUsuarioSair();
                break;
            }
            default: {
                // console.log('default case do seleceiona menu');
                break;
            }
        }
    }

}
