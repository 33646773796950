<!DOCTYPE html>
<html lang="en">
<head>
    <link rel="icon" type="image/x-icon">
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>    
</head>
<body>

        <div>
          <!--faq-->
            <br>
            <br>

            <h3>FAQ - Perguntas e Respostas</h3>
            
            <br>
            <br>

            <!--Item 1-->
            <a data-toggle="collapse" class="collapsed" href="#faq1">1) Como funciona o Validador de Leiautes?</a>
            <div id="faq1" class="collapse">
                <p>Basta selecionar um arquivo em formato "texto" e clicar em "Analisar".<br>
                A tela seguinte retornará um relatório com possíveis erros.<br>
                O Validador é uma ferramenta que automatiza a verificação de regras dos leiautes padronizados Febraban ou proprietário BB,<br>
                que não substitui o processo de homologação antes do envio do arquivo para o ambiente de produção.</p>
            </div>
            <br>
			
			<!--Item 2-->
            <a data-toggle="collapse" class="collapsed" href="#faq2">2) Qual codificação devo usar na geração do arquivo?</a>
            <div id="faq2" class="collapse">
                <p>Os arquivos submetidos devem estar na codificação ANSI (ISO-8859-1).</p>
            </div>
            <br>
			
			<!--Item 3-->
            <a data-toggle="collapse" class="collapsed" href="#faq3">3) Quais tipos de validações são feitas nos campos?</a>
            <div id="faq3" class="collapse">
                <p>Os campos são validados em relação ao respectivo tipo numérico ou alfanumérico.<br>
				As validações são apenas posicionais, não valida dados cadastrais.<br>
			    O dígito verificador da agência, conta e CPF são calculados em relação ao módulo 10 e o dígito verificador do CNPJ é calculado em relação ao módulo 11.</p>
            </div>
            <br>
			
			<!--Item 4-->
            <a data-toggle="collapse" class="collapsed" href="#faq4">4) Como devo informar campos numéricos?</a>
            <div id="faq4" class="collapse">
                <p>Sempre à direita e preenchidos com zeros à esquerda.</p>
            </div>
            <br>
			
		    <!--Item 5-->
            <a data-toggle="collapse" class="collapsed" href="#faq5">5) Como devo informar campos alfanuméricos?</a>
            <div id="faq5" class="collapse">
                <p>Sempre à esquerda e preenchidos com brancos à direita.</p>
            </div>
            <br>
			
			<!--Item 6-->
            <a data-toggle="collapse" class="collapsed" href="#faq6">6) Qual o tamanho da linha do arquivo?</a>
            <div id="faq6" class="collapse">
                <p>O tamanho da linha irá depender do leiaute à ser utilizado, por exemplo:<br> 
				CNAB240 (240 posições), CNAB750 - PIX (750 posições), DBT627 - Débito Automático (150 posições), etc.<br>
				Se o arquivo for por exemplo de 240 posições, preencher com brancos até a coluna 240.</p>
            </div>
            <br>
			
			<!--Item 7-->
            <a data-toggle="collapse" class="collapsed" href="#faq7">7) Para leiaute de Cobrança CBR641 de 400 posições, como saber se o convênio é de 6 ou 7 posições?</a>
            <div id="faq7" class="collapse">
                <p>Os convênios de 6 posições possuem a numeração de 000001 até 999999.<br>
				 Os convênios de 7 posições possuem a numeração de 1000000 até 9999999.</p>
            </div>
            <br>

			<!--Item 8-->
            <a data-toggle="collapse" class="collapsed" href="#faq8">8) O que fazer se o meu leiaute não constar na lista de leiautes?</a>
            <div id="faq8" class="collapse">
                <p>O leiaute do arquivo é identificado automaticamente, na situação em que não for identificado, possívelmente há inconsistências, neste caso,<br>
				efetue contato pelo e-mail (validaleiaute@bb.com.br) para solicitar a validação.</p>
            </div>
            <br>
			
		    <!--Item 9-->
            <a data-toggle="collapse" class="collapsed" href="#faq9">9) Como preencher campos opcionais?</a>
            <div id="faq9" class="collapse">
                <p>Informar zeros para campos numéricos e brancos para campos alfanuméricos.</p>
            </div>
            <br>
			
			<!--Item 10-->
            <a data-toggle="collapse" class="collapsed" href="#faq10">10) Porque não utilizar a codificação UTF-8?</a>
            <div id="faq10" class="collapse">
                <p>A codificação UTF-8 oferece suporte para vários idiomas e há caracteres como por exemplo o 'Ç' que causam deslocamento de informações no arquivo.</p>
            </div>
            <br>

          <!--faq-->
        </div>

</body>
</html>


