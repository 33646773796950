import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from './shared/service/global.service';
import { UtilApi } from './shared/service/api/util.api';
import { ModalService } from './shared/service/modal.service';
import { LoadingService } from './shared/service/loading.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  isLogedSubscription: Subscription;
  isLoged: boolean;
  booleanAnimacao = false;

  constructor(
    public globalService: GlobalService,
    private utilApi: UtilApi,
    public modalService: ModalService,
    private matomoTracker: MatomoTracker
  ) {}

  ngOnInit(): void {
    console.log('Matomo tracking test');
    this.matomoTracker.enableCrossDomainLinking();
    this.matomoTracker.trackAllContentImpressions();
    this.matomoTracker.trackPageView();

    this.utilApi.invocacaoRemotaComRetry(
      5,
      1000,
      this,
      AppComponent.prototype.carregarConf
    );

    LoadingService.getMessage().subscribe((retorno) => {
      if (retorno) {
        this.blockUI.start('Carregando...');
      }
      if (LoadingService.requisicoesAtivas === 0) {
        this.blockUI.reset();
      }
    });
  }

  carregarConf(): void {
    this.globalService.carregarConf();
  }
}
