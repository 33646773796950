<block-ui>

  <app-topo>
  </app-topo>
  <div class="container-fluid">
       <router-outlet></router-outlet>
  </div>
  <app-rodape>
  </app-rodape>

  <!-- Modal Mensagem-->
  <div aria-labelledby="modalLabel" class="modal fade" data-backdrop="static" data-focus="false" id="modal"
       role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h4 [innerHTML]="modalService.titulo" class="modal-title" id="modalLabel"></h4>
          <button (click)="modalService.fecharPaginaModal()" aria-label="Close" class="close" data-dismiss="modal"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body clearfix">
          <router-outlet name="modal"></router-outlet>
          <div [innerHTML]="modalService.mensagem"></div>
        </div>

        <div *ngIf="modalService.fechar != ''" class="modal-footer">
          <button (click)="modalService.copyText()" *ngIf="modalService.enableCopy" class="btn btn-outline-primary"
                  type="button">Copiar
          </button>
          <button (click)="modalService.fecharPaginaModal()" class="btn btn-outline-primary" data-dismiss="modal"
                  type="button">{{modalService.fechar}}</button>
        </div>

      </div>
    </div>
  </div>

  <!-- Modal -->
  <div aria-labelledby="modalLabel" class="modal fechado" id="loginModal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="z-index:2000">
        <div class="modal-header">
        </div>
        <div class="modal-body clearfix">
          <app-login-modal></app-login-modal>
        </div>
      </div>
    </div>
  </div>
  <div class="fechado" id="loginModalOverlay"></div>
</block-ui>
