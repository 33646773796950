import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public static requisicoesAtivas = 0;
  private static exibirLoadingSubject = new Subject<boolean>();

  constructor() {
  }

  static sendMessage(message: boolean): void {
    if (message) {
      this.requisicoesAtivas = this.requisicoesAtivas + 1;
    } else {
      this.requisicoesAtivas = this.requisicoesAtivas - 1;
    }
    LoadingService.exibirLoadingSubject.next(message);
  }

  static getMessage(): Observable<boolean> {
    return LoadingService.exibirLoadingSubject.asObservable();
  }

  static unsubscribe(): void {
    LoadingService.exibirLoadingSubject.unsubscribe();
  }

}
