import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { LoadingService } from '../service/loading.service';
import { GlobalService } from '../service/global.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  token = '';

  constructor(private globalService: GlobalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    LoadingService.sendMessage(true);
    let requestCompleted = false;
    let requestError = false;
    const authHeader = this.getAuthorizationHeader();
    if (authHeader !== '') {
      const authReq = req.clone({ setHeaders: { Authorization: authHeader } });
      return next.handle(authReq).pipe(
        tap((ev: HttpEvent<any>) => {
          if (ev instanceof HttpResponse) {
            requestCompleted = true;
          }
        }),
        catchError((response) => {
          if (response instanceof HttpErrorResponse) {
            requestError = true;
          }
          return observableThrowError(response);
        }),
        finalize(() => {
          if (requestCompleted || requestError) {
            LoadingService.sendMessage(false);
          }
        })
      );
    } else {
      return next.handle(req).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            requestCompleted = true;
          }
        }),
        catchError((responseError) => {
          if (responseError instanceof HttpErrorResponse) {
            requestError = true;
          }
          return observableThrowError(responseError);
        }),
        finalize(() => {
          LoadingService.sendMessage(false);
        })
      );
    }
  }

  getAuthorizationHeader(): string {
    if (this.globalService.estaAutenticado()) {
      return 'Bearer ' + this.globalService.usuario.access_token;
    } else {
      return '';
    }
  }
}
