// @ts-ignore
import { version } from '../../package.json';

export const environment = {
  production: true,
  version,
  config: {
    api_escopo: 'sia:usuario',
    // api_validador: 'http://localhost:7001/api/validaleiaute',
    api_autorizador:
      'https://gmtedi.desenv.bb.com.br:43000/gmt-autorizador-api',
    api_validador: 'https://validadorleiaute.api.iga.desenv.bb.com.br',
    // api_validador: 'https://validadorleiauteapi.iga.desenv.bb.com.br',
    // api_autorizador: 'https://gmtedi.hm.bb.com.br/gmt-autorizador-api',
    matomoSiteId: 597,
    matomoURL: '//webanalytics.intranet.bb.com.br//',
  },

  configDesenv: {
    api_escopo: 'sia:usuario',
    api_validador: 'http://validaleiaute.iga.desenv.bb.com.br/api',
    api_autorizador:
      'https://gmtedi.desenv.bb.com.br:43000/gmt-autorizador-api',
    matomoSiteId: 597,
    matomoURL: '//webanalytics.desenv.bb.com.br/',
  },

  configHm: {
    api_escopo: 'sia:usuario',
    api_validador: 'https://validaleiaute.iga.hm.bb.com.br/api',
    api_autorizador: 'https://gmtedi.bb.com.br/gmt-autorizador-api',
  },

  configProd: {
    api_escopo: 'sia:usuario',
    api_validador: 'https://validaleiautes.bb.com.br/api',
    api_autorizador: 'https://gmtedi.bb.com.br/gmt-autorizador-api',
    matomoSiteId: 597,
    matomoURL: '//webanalytics.intranet.bb.com.br//',
  },
};
