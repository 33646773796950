<div class='container' style="margin-top: 150px;">
  <div class="row justify-content-left">
    <div class="col-12">
      <h2 class="azulBB">
        Validador de Leiautes
      </h2>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="row card justify-content-center" style="margin-top: 20px;">
      <div class="card-body col-12">

        <div id="alerta-leiaute-sucesso" class="alert alert-success alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-leiaute-sucesso')">&times;</button>
          Leiaute identificado {{ leiaute?.label }}. Clique em ANALISAR.
        </div>

        <div id="alerta-arquivo-sucesso" class="alert alert-success alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-arquivo-sucesso')">&times;</button>
          Arquivo pronto para ser transmitido.
        </div>

        <div id="alerta-arquivo-erro" class="alert alert-danger alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-arquivo-erro')">&times;</button>
          Arquivo com {{ qtdErros }} erros, favor ajustar.
        </div>

        <div id="alerta-arquivo-vazio" class="alert alert-danger alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-arquivo-vazio')">&times;</button>
          Arquivo sem registros. Favor verificar.
        </div>

        <div id="alerta-arquivo-tamanho-excedido" class="alert alert-danger alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-arquivo-tamanho-excedido')">&times;</button>
          Arquivo maior que o tamanho máximo permitido (1,5 MB).
        </div>

        <div id="alerta-arquivo-retorno" class="alert alert-danger alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-arquivo-retorno')">&times;</button>
          Foi identificado o leiaute RETORNO, não previsto para validação. Caso o leiaute desejado não conste na lista abaixo, efetue contato pelo e-mail [validaleiaute@bb.com] para solicitar a validação.
        </div>
        <div id="alerta-arquivo-warning" class="alert alert-warning alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-arquivo-warning')">&times;</button>
          Arquivo com {{ qtdAlertas }} alertas, favor ajustar.
        </div>

        <div id="alerta-leiaute-error" class="alert alert-danger alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-leiaute-error')">&times;</button>
          Não foi possível identificar o leiaute desse arquivo. Caso o leiaute desejado não conste na lista abaixo, efetue contato pelo e-mail (validaleiaute@bb.com.br) para solicitar a validação.
        </div>

        <div id="alerta-encoding-alerta" class="alert alert-warning alert-dismissable">
          <button type="button" class="close" (click)="fechaAlerta('alerta-encoding-alerta')">&times;</button>
          O encoding do arquivo é: {{ encoding }}, favor converter para o formato ANSI.
        </div>
  
        <div class="row justify-content-center">
          <div class="form-group col-lg-7">
            <label for="arquivo">
              Arquivo
            </label>
            <input [value]="nomeArquivoSelecionado" class="form-control" id="arquivo" readonly="true" type="text">
            <label class="custom-file-upload label-bb">
              <input (change)="onFileSelect($event)" type="file" />
              <i class="fa fa-paperclip fa-2x label-bb"></i> SELECIONAR ARQUIVO
            </label>
          </div>

          <div class="form-group col-lg-3">
            <label for="leiaute">
              Selecione o Leiaute
            </label>
            <select [compareWith]="compareFn" class="form-control" formControlName="leiaute" id="leiaute">
              <option *ngFor="let lt of listaLeiaute" [ngValue]="lt">{{lt.label}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2 col-6 ">
            <label for="btn-analisar">&nbsp;
            </label>
            <button (click)="analisar()" [disabled]="!(form.get('arquivo').value !== null && form.get('leiaute').value)"
              [ngClass]="{'btn-secondary-bb': respostaAnalise, 'btn-primary-bb': !respostaAnalise}"
              class="form-control btn btn-primary" id="btn-analisar" style="min-width: 40px;" type="button">

              ANALISAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <br>
  <app-survey *ngIf="this.globalService.ambienteLogado" [booleanAnimacao]="booleanAnimacao"></app-survey>
  <app-survey *ngIf="this.globalService.ambienteLogado==false" [booleanAnimacaoExterno]="booleanAnimacaoExterno"></app-survey>
  
  <form *ngIf="respostaAnalise">
    <div class="row card justify-content-center">
      <div class="card-body col-12">
        <div class="row justify-content-center">
          <div class="col-lg-8 border-right" style="margin-bottom: 18px">
            <div class="row justify-content-around">
              <div class="col-2 text-center">
                <i class="fa fa-file-text fa-3x azulClaroBB"></i>
              </div>

              <div class="form-group col-10">
                <label for="nomeArquivo">
                  Arquivo
                </label>
                <input [value]="respostaAnalise.nomeArquivo" class="form-control-plaintext" id="nomeArquivo" readonly
                  type="text">
              </div>
            </div>
          </div>

          <div class="form-group col-lg-2 border-right">
            <label for="convenio">
              Convênio
            </label>
            <input [value]="getValorAtributo('Convênio')" class="form-control-plaintext" id="convenio" readonly type="text"> 
          </div>

          <div class="form-group col-lg-2">
            <label for="dataHoraAnalise">
              Data e hora da análise
            </label>
            <input [value]="respostaAnalise.dataHoraAnalise | date:'dd/MM/yyyy HH:mm'" class="form-control-plaintext"
              id="dataHoraAnalise" readonly type="text">
          </div>
        </div>
        
        <div class="row justify-content-center">
          <div class="form-group col border-right">
            <label for="produto">
              Produto
            </label>
            <input [value]="getValorAtributo('Produto')" class="form-control-plaintext" id="produto" readonly type="text"> 
          </div>

          <div class="form-group col border-right">
            <label for="formato">
              Formato
            </label>
             <input [value]="getValorAtributo('Formato')" class="form-control-plaintext" id="formato" readonly type="text"> 
          </div>

          <!-- Exibe o Tipo de Serviço de Pagamento CNAB240 -->
          <div *ngIf="respostaAnalise.tipoServicoPGT" class="form-group col-lg-3 border-right">
            <label for="tipoServicoPGT">Tipo de Serviço</label>
            <input [value]="respostaAnalise.tipoServicoPGT" class="form-control-plaintext" id="tipoServicoPGT" readonly type="text">
          </div>
          <!-- Exibe a Carteira de Cobrança CNAB240/CBR641 -->
          <div *ngIf="respostaAnalise.carteiraCBR" class="form-group col-lg-3 border-right">
            <label for="carteiraCBR">Carteira</label>
            <input [value]="respostaAnalise.carteiraCBR" class="form-control-plaintext" id="carteiraCBR" readonly type="text">
          </div>
          <div *ngIf="!respostaAnalise.tipoServicoPGT && !respostaAnalise.carteiraCBR" class="form-group col-lg-2 border-right">
            <label></label>
          </div>

          <!-- Exibe a Forma de Lançamento de Pagamento CNAB240 -->
          <div *ngIf="respostaAnalise.tipoServicoPGT" class="form-group col-lg-3">
           <label for="formaLancamentoPGT">Forma de Lançamento</label>
           <input [value]="respostaAnalise.formaLancamentoPGT" class="form-control-plaintext" id="formaLancamentoPGT" readonly type="text">
          </div>
          <!-- Exibe a Variação de Cobrança CNAB240/CBR641 -->
          <div *ngIf="respostaAnalise.variacaoCBR" class="form-group col-lg-3">
            <label for="variacaoCBR">Variação</label>
            <input [value]="respostaAnalise.variacaoCBR" class="form-control-plaintext" id="variacaoCBR" readonly type="text">
           </div>
          <div *ngIf="!respostaAnalise.tipoServicoPGT && !respostaAnalise.variacaoCBR" class="form-group col-lg-2">
            <label></label>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="form-group col border-right">
            <label for="qtdErros">
              Quantidade de erros
            </label>
            <input [value]="qtdErros" class="form-control-plaintext" id="qtdErros" readonly type="text">
          </div>

          <div class="form-group col border-right">
            <label for="qtdAlertas">
              Quantidade de alertas
            </label>
            <input [value]="qtdAlertas" class="form-control-plaintext" id="qtdAlertas" readonly type="text">
          </div>

          <div class="form-group col-lg-3 border-right">
            <label for="qtdRegistros">
              Quantidade de Linhas
            </label>
            <input [value]="respostaAnalise.qtdLinhas" class="form-control-plaintext" id="qtdRegistros" readonly
              type="text">
          </div>

          <div class="form-group col-lg-3">
            <label for="tamanho">
              Tamanho
            </label>
            <input [value]="respostaAnalise.tamanho + ' Bytes'" class="form-control-plaintext" id="tamanho" readonly
              type="text">
          </div>
        </div>
      </div>
    </div>
  </form>
  <br>
  <div [hidden]="!respostaAnalise" class="row card justify-content-center">
    <div class="card-body col-12">
      <div class="row justify-content-between"
        style="padding-left: 0px; padding-right: 0px; margin-bottom: 0px;padding-bottom: 0px;">
        <div class="col text-left">
          <h4 class="azulClaroBB">Análise</h4>
        </div>

        <div class="col">
          <div class="custom-control custom-control-right custom-switch">
            <input (change)="filtrar()" [(ngModel)]="exibeErrosSomente" class="custom-control-input" id="customSwitch1"
              type="checkbox">
            <label class="custom-control-label" for="customSwitch1">Exibe somente
              erros: {{exibeErrosSomente ? 'Sim' : 'Não'}}</label>
          </div>
        </div>

        <div class="col text-right">
          <div class="btn-group">
            <button aria-expanded="false" style="width:200" id="btn-relatorio" aria-haspopup="true"
              class="form-control btn btn-primary btn-primary-bb dropdown-toggle" data-toggle="dropdown">
              GERAR RELATÓRIO
            </button>
            <div class="dropdown-menu text-center">
              <a (click)="copiarTXT()" class="dropdown-item azulBB">TXT</a>
              <div class="dropdown-divider"></div>
              <a (click)="geraPDF()" class="dropdown-item azulBB">PDF</a>
              <div class="dropdown-divider"></div>
              <a (click)="gerarCSV()" class="dropdown-item azulBB">CSV</a>
              <div class="dropdown-divider"></div>
              <a (click)="gerarXLS()" class="dropdown-item azulBB">XLS</a>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div [hidden]="!respostaAnaliseNaoNormalizada" class="row justify-content-center">
        <div class="col-12">
          <table [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-hover table-striped" datatable
            id="resultadoAnalise">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th class="azulBB2" scope="col"></th>
                <th class="azulBB2" scope="col"></th>
                <th class="azulBB2" scope="col"></th>
                <th class="azulBB2" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of respostaAnaliseFinal">
                <tr *ngIf="item.tipoCampo === 'R'">
                  <td style="display: none;">{{item.indice}}</td>
                  <td class="novoHeader" colspan="5">
                    LINHA {{item.numeroLinha}} - {{item.tipoRegistro}}
                  </td>
                  <td style="display: none;"></td>
                  <td style="display: none;"></td>
                  <td style="display: none;"></td>
                  <td style="display: none;"></td>
                </tr>
                <tr *ngIf="item.tipoCampo === 'R'" >
                  <td class="novoHeader2">{{item.indice}}</td>
                  <td class="novoHeader2">POSIÇÃO</td>
                  <td class="novoHeader2">NOME DO CAMPO</td>
                  <td class="novoHeader2">CONTEÚDO OBSERVADO</td>
                  <td class="novoHeader2" style="white-space: nowrap;">
                    ERRO/ALERTA
                    <img alt="iconeTooltip" class="iconeTooltip" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAIAAABL1vtsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGTSURBVDhPpZS9a8JAGMbzr2mHKOqigxZEQVwMCC66NBRucolLi4ubmCUu7aAuTjpoFx3MkkESBKGQJRDI3vc+klzkYkv9cUPuee997iPvnRQ8jMjiai6nqFd/LuazmadsJl+q1XtoujpdWfyGpIVvfaCmDGnilqu9flo+GxvBWVzWqEqGFpr9yerkuF442nPMzQw1CiRaVZdnplNCi/PqheSXVcE8DFijWiEuo53LNIBY+LbRzeF8bc+FQPfc73gtBHenYRe5O7dDHVtcFwO8f0WPVMpOw76ytmd9im/rCj6X/oIdL1hYegukytuRKjFiC+D4XoYpW7pFelJw1ttcP4FgIxQ6q2I4uCMF2yFMVRwfSCzGNhTQYXWjL49JHIdxCaLDLf6W6NCOYZNQTGghCAF8VqoF8MdQ6kaAOxaJjQTOvJNynOkW9DjbOilT8U+lyXxLGN3+VFFp3bMQlVZ6gQtIKXCMux89dM0o3GVHs83JCSsKatQxl5NBeNnR+sIiFM4C+O3JaaD7T07EQw/fPwiCHyx/VRr79TwiAAAAAElFTkSuQmCC" />
                    <div class="tooltipCima">
                      <table style="width: 375px;">
                        <tr>
                          <td>
                            <img
                                alt="erroEstrutura"
                                style="width: 17px;"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAEkSURBVHjaYvj//z/Dq3yn/08tTYnHTgX/X9/9yfT//38GQpjh40J/mMadJOD/T50KVhFjCcP7KRBXvbzwX5AYF72fArWASEsos4AISyi3AGoJtS3AwLj0k2wBLh+NIAtwhTGusB58FgzfOMBlAL0soF1Go3ppig3/eXZaDF3s81mIGOUWvDki/yLCdCdygfd5S0HVU0vTnU/r9mVTxQcfFiZ1PrWEWPJyFtRwp5hFLy/8FKRaHEAtgRbfEMMpqTIxUg40WGD1wyJ4JP//doPjVb7T3KeWpqtJxS8v/GdECXOnmM3P65ImPrU0bYdZQnlSfLOD/bmTac1Tp5jNLy/8FIIG18Snlqb5T6t36FElrf96vEPjDdRweAbcspHn////DIABADaJJSwcdjOxAAAAAElFTkSuQmCC">
                          </td>
                          <td>
                            Erros de estrutura: Impedem o processamento
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                                alt="erroProcessamento"
                                style="width: 15px;"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAC0SURBVHjaYvj//z/Dn2f7mN5c+Cn0//9/BlLxh11HuGBshv9vdjA+dzJd/dQpZvNLEg38uKqg8aml6eGn1RunQQz7/5/h08aCgqeWpBkIN8gp5tXLCz8l4IaRaiA2g1AMI9bATxuxG4RhGCED8RmE1TBcBhIyCKdh6Aa+aCdsEF7DkAz8/9TS9P9Tp5j/+Ayin2FU8ybVIoBqSYNqiZZq2Yl6Gf3NDi6qFkF/nu0zoUbhCBgAQQS4owMTyaUAAAAASUVORK5CYII=">
                          </td>
                          <td>
                            Erros: impedem o processamento
                          </td>
                        </tr>
                        <tr>
                          <td style="vertical-align: top;">
                            <img alt="alertaAjuste" style="margin-top: -4px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAADASURBVHjarJS7DcMwDERZZIDUQSbxEClUaxy1HsMTqHMbw60GUKEyQJa4FJHzEWye4qhgRz09HEgKAGlVvCk6C+9sG9goCV4Swu34Hyw6Cy8JXlBjV2HlbIZemR2zegMW8C5Y+bjCTs+qfEjsuFUwF3jT19jxrILpXzBix7MqYYqdbrUGU+yENq3BNuxoA+5Dh3HoaBxfsK1gp7ybEQdmp88VIMB8wjSfawb78zIgL/XvlSWeP++FLJXNpeWlfQwAlhwKTvAUgcEAAAAASUVORK5CYII=">
                          </td>
                          <td>
                            Alertas: recomendação de ajuste
                            <br />
                            (não impede o processamento)
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                  <td class="novoHeader2">SOLUÇÃO/RECOMENDAÇÃO</td>
                </tr>
              <tr *ngIf="item.tipoCampo !== 'R' || item.observacoes.length > 0">
                <td>{{item.indice}}</td>
                <td>{{item.posicao}}</td>
                <td>{{item.nomeCampo}}</td>
                <td
                  class="fonteCourier"
                  style="word-wrap: break-word; max-width: 230px; white-space: pre-wrap;"
                  [innerHTML]="item.conteudoObservado == '-' ? '-' : '['+item.conteudoObservado+']'"></td>
                <td>
                  <img
                      alt="Ok"
                      *ngIf="!exibeErrosSomente && item.observacoes.length == 0"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAOCAYAAADNGCeJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAADBSURBVHjavNMxCoMwFAZgL5KDOLvqZkZXj5PFQQSXLA5KIFuHQovQoSeQxiIVb+AJ/m4B02iDlg5v+99HeO/FA+D9qg41s46BthSsYziEZY8MRBAQQUBbuh/LVa4hIsj+l5XP0gpprB5r+Ccf0TXCFsQHvgpprOgLHQgvoRWsXtUmpDE1KwTnQAfjNl4Em7H5Ci1mZoLJLQEAT07SCfpYgAmm99QZsm7TBF2h1dMwQRdo887UrMAHDjlJ/OVvmvUeAHjq7yFKqP+6AAAAAElFTkSuQmCC"
                    />
                  <div *ngFor="let _msg of item.observacoes">
                    <p *ngIf="_msg.tipo === 'ERR'">
                        <img
                          alt="ErrorD"
                          style="width: 15px;"
                          *ngIf="item.tipoCampo === 'D'"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAC0SURBVHjaYvj//z/Dn2f7mN5c+Cn0//9/BlLxh11HuGBshv9vdjA+dzJd/dQpZvNLEg38uKqg8aml6eGn1RunQQz7/5/h08aCgqeWpBkIN8gp5tXLCz8l4IaRaiA2g1AMI9bATxuxG4RhGCED8RmE1TBcBhIyCKdh6Aa+aCdsEF7DkAz8/9TS9P9Tp5j/+Ayin2FU8ybVIoBqSYNqiZZq2Yl6Gf3NDi6qFkF/nu0zoUbhCBgAQQS4owMTyaUAAAAASUVORK5CYII=">
                        <img
                          alt="ErrorR"
                          style="width: 17px;"
                          *ngIf="item.tipoCampo === 'R'"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAEkSURBVHjaYvj//z/Dq3yn/08tTYnHTgX/X9/9yfT//38GQpjh40J/mMadJOD/T50KVhFjCcP7KRBXvbzwX5AYF72fArWASEsos4AISyi3AGoJtS3AwLj0k2wBLh+NIAtwhTGusB58FgzfOMBlAL0soF1Go3ppig3/eXZaDF3s81mIGOUWvDki/yLCdCdygfd5S0HVU0vTnU/r9mVTxQcfFiZ1PrWEWPJyFtRwp5hFLy/8FKRaHEAtgRbfEMMpqTIxUg40WGD1wyJ4JP//doPjVb7T3KeWpqtJxS8v/GdECXOnmM3P65ImPrU0bYdZQnlSfLOD/bmTac1Tp5jNLy/8FIIG18Snlqb5T6t36FElrf96vEPjDdRweAbcspHn////DIABADaJJSwcdjOxAAAAAElFTkSuQmCC">
                      <span>{{' ' +
                        _msg.mensagem + ' '}}</span></p>
                    <p *ngIf="_msg.tipo === 'WAR'">
                      <img alt="warning" style="margin-top: -4px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAADASURBVHjarJS7DcMwDERZZIDUQSbxEClUaxy1HsMTqHMbw60GUKEyQJa4FJHzEWye4qhgRz09HEgKAGlVvCk6C+9sG9goCV4Swu34Hyw6Cy8JXlBjV2HlbIZemR2zegMW8C5Y+bjCTs+qfEjsuFUwF3jT19jxrILpXzBix7MqYYqdbrUGU+yENq3BNuxoA+5Dh3HoaBxfsK1gp7ybEQdmp88VIMB8wjSfawb78zIgL/XvlSWeP++FLJXNpeWlfQwAlhwKTvAUgcEAAAAASUVORK5CYII=">
                      <span>{{' ' +
                        _msg.mensagem + ' '}}</span></p>
                  </div>
                </td>
                <td>
                  <img
                      alt="Ok"
                      *ngIf="!exibeErrosSomente && item.observacoes.length == 0"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAOCAYAAADNGCeJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAADBSURBVHjavNMxCoMwFAZgL5KDOLvqZkZXj5PFQQSXLA5KIFuHQovQoSeQxiIVb+AJ/m4B02iDlg5v+99HeO/FA+D9qg41s46BthSsYziEZY8MRBAQQUBbuh/LVa4hIsj+l5XP0gpprB5r+Ccf0TXCFsQHvgpprOgLHQgvoRWsXtUmpDE1KwTnQAfjNl4Em7H5Ci1mZoLJLQEAT07SCfpYgAmm99QZsm7TBF2h1dMwQRdo887UrMAHDjlJ/OVvmvUeAHjq7yFKqP+6AAAAAElFTkSuQmCC"
                    />
                  <div *ngFor="let _msg of item.observacoes" style="color: #035aa5;">
                    <!-- Orientação para codificação ANSI -->
                    <p *ngIf="encoding == 'ANSI' && _msg.tipo === 'SOL'"><span>{{' ' +
                        _msg.mensagem + ' '}}</span></p>
                    <p *ngIf="encoding != 'ANSI' && _msg.tipo === 'SOL' && (_msg.mensagem == 'Adequar a linha para conter 240 caracteres.' || _msg.mensagem == 'Adequar a linha para conter 400 caracteres.' || 
                                                                            _msg.mensagem == 'Adequar a linha para conter 750 caracteres.' || _msg.mensagem == 'Adequar a linha para conter 150 caracteres.' || 
                                                                            _msg.mensagem == 'Adequar a linha para conter 300 caracteres.')"><span>{{' ' +
                        _msg.mensagem + ' Ajustar a codificação do arquivo para ANSI.'}}</span></p>  
                    <p *ngIf="encoding != 'ANSI' && _msg.tipo === 'SOL' && (_msg.mensagem != 'Adequar a linha para conter 240 caracteres.' && _msg.mensagem != 'Adequar a linha para conter 400 caracteres.' && 
                                                                            _msg.mensagem != 'Adequar a linha para conter 750 caracteres.' && _msg.mensagem != 'Adequar a linha para conter 150 caracteres.' && 
                                                                            _msg.mensagem != 'Adequar a linha para conter 300 caracteres.')"><span>{{' ' +
                        _msg.mensagem + ' '}}</span></p>
                  </div>
                </td>
              </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>