<div class="container">

  <form [formGroup]="loginForm" (ngSubmit)="loginUsuario()" class="form-signin">
    <div class="form-signin-heading ">
      <img src="./assets/imagens/logo.png" alt="Logotipo Banco do Brasil" />
      <h2 class="text-center">VALIDADOR DE LEIAUTES</h2>
    </div>

    <br />

    <label for="chave" class="sr-only">Chave</label>
    <input autofocus type="text" name="chave" id="chave" [formControl]="chave" placeholder="Nome do Usuário" class="form-control"
           (keyup)="pulaProximoCampo($event, focusable)" />

    <label for="senha" class="sr-only">Senha</label>
    <input #focusable type="password" name="senha" id="senha" [formControl]="senha" placeholder="Senha" class="form-control"
    />

    <div class="login-mensagens">
      <div [hidden]="!mensagens" class="alert alert-danger" role="alert">{{mensagens}}</div>
      <div [hidden]="!globalService.timedOut" class="alert alert-danger" role="alert">Desconectado por inatividade</div>
      <div [hidden]="!erroChave" class="alert alert-warning">Favor inserir o nome do usuário</div>
      <div [hidden]="!erroSenha" class="alert alert-warning">Favor inserir a senha</div>
    </div>

    <button class="btn btn-lg btn-primary btn-block botao-login" type="submit">Logar</button>
  </form>

</div>
