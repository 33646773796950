import { environment } from '../../../environments/environment';
import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import { Usuario } from '../model/usuario';
import { ModalService } from './modal.service';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { UserIdleService } from '../../modules/user-idle-module/user-idle.service';
import { UserIdleConfig } from '../../modules/user-idle-module/user-idle-config';

@Injectable({
  providedIn: 'root',
})
export class GlobalService implements OnDestroy {
  userIdle: UserIdleService;
  userIdleConfig: UserIdleConfig;
  usuario: Usuario;
  autenticado = false;
  usuarioChange: Subject<Usuario> = new Subject<Usuario>();
  isLogedSubject: Subject<boolean> = new Subject<boolean>();
  configuracao;
  ambienteLogado = true;
  erro = false;
  loading = false;
  timedOut = false;
  lastPing?: Date = null;
  escopos: string[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private ngZone: NgZone,
    private modalService: ModalService
  ) {
    this.userIdleConfig = new UserIdleConfig();
    this.userIdleConfig.idle = 1500;
    this.userIdleConfig.ping = 1680;
    // this.userIdleConfig.idle = 10;
    // this.userIdleConfig.ping = 15;
    this.userIdleConfig.timeout = 30;
  }

  isLogedObservable(): Observable<boolean> {
    return this.isLogedSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.userIdle.stopWatching();
  }

  keepAliveFunction(): void {
    if (this.autenticado) {
      this.renovarToken(this.usuario.refresh_token).subscribe(
        (usuario) => {
          const now = new Date();
          this.usuario.access_token = usuario.access_token;
          this.usuario.data_expiracao = usuario.data_expiracao;
          this.usuario.emitido_em = now.getTime().toString();
          this.autenticado = true;
          this.lastPing = new Date();
        },
        (error) => {
          console.log('Erro ao renovar token no onping do keepalive');
          this.lastPing = null;
          this.logoutUsuarioModal();
        }
      );
    }
  }

  carregarConf(): void {
    const ambiente = location.hostname;
    const caminhoCompleto = location.href;
    switch (true) {
      case ambiente.indexOf('hm.bb.com.br') > 0:
        this.configuracao = environment.configHm;
        break;
      case ambiente.indexOf('desenv.bb.com.br') > 0:
        this.configuracao = environment.configDesenv;
        break;
      case ambiente.indexOf('bb.com.br') > 0:
        this.configuracao = environment.configProd;
        break;
      default:
        this.configuracao = environment.config;
        break;
    }

    switch (true) {
      case caminhoCompleto.indexOf('validaleiaute') > 0:
        this.ambienteLogado = false;
        this.cookieService.putObject('usuarioCadastro', null, null);
        localStorage.setItem('access_token', '');
        this.autenticado = false;
        this.usuario = null;
        this.usuarioChange.next(null);
        localStorage.clear();
        sessionStorage.clear();
        this.userIdle.stopWatching();
        this.userIdle = null;
        this.isLogedSubject.next(false);
        break;
      case caminhoCompleto.indexOf('validadorleiaute') > 0:
        this.ambienteLogado = true;
        break;
    }

    // verificar se tem usuario no cookie
    const usuario = this.cookieService.getObject('usuarioCadastro') as Usuario;
    if (usuario != null && !this.tokenExpirado()) {
      this.loading = true;
      this.lastPing = new Date();
      this.renovarToken(usuario.refresh_token).subscribe(
        (usuarioToken) => {
          const now = new Date();
          usuario.access_token = usuarioToken.access_token;
          usuario.data_expiracao = usuarioToken.data_expiracao;
          usuario.emitido_em = now.getTime().toString();
          this.loginUsuario(usuario);
          this.loading = false;
        },
        (error) => {
          console.log('Erro ao obter refresh token no carregaConf');
          this.timedOut = true;
          this.logoutUsuarioModal();
        }
      );
    }
  }

  logoutUsuarioModal(): void {
    this.cookieService.putObject('usuarioCadastro', null, null);
    localStorage.setItem('access_token', '');
    this.autenticado = false;
    this.usuario = null;
    this.usuarioChange.next(null);
    localStorage.clear();
    sessionStorage.clear();
    this.userIdle.stopWatching();
    this.userIdle = null;
    this.isLogedSubject.next(false);
    this.abrirModalLogin();
  }

  logoutUsuarioSair(): void {
    this.cookieService.putObject('usuarioCadastro', null, null);
    localStorage.setItem('access_token', '');
    this.autenticado = false;
    this.usuario = null;
    this.usuarioChange.next(null);
    localStorage.clear();
    sessionStorage.clear();
    this.userIdle.stopWatching();
    this.userIdle = null;
    this.isLogedSubject.next(false);
    this.router.navigate(['/login']);
  }

  getConfiguracao(): any {
    return this.configuracao;
  }

  loginUsuario(usuario: Usuario): void {
    const options: CookieOptions = {};
    const data = new Date();
    if (data.getHours() > 2) {
      data.setHours(data.getHours() - 3);
    } else {
      data.setHours(21 + data.getHours());
    }
    if (data.getMinutes() < 30) {
      data.setMinutes(data.getMinutes() + 30);
    } else {
      if (data.getHours() < 23) {
        data.setHours(data.getHours() + 1);
      } else {
        data.setHours(0);
      }
      data.setMinutes(data.getMinutes() - 30);
    }
    options.expires = data;
    this.cookieService.putObject('usuarioCadastro', usuario, null);
    localStorage.setItem('access_token', usuario.access_token);
    this.timedOut = false;
    this.usuario = usuario;
    this.autenticado = true;
    this.usuarioChange.next(this.usuario);
    this.userIdle = new UserIdleService(this.userIdleConfig, this.ngZone);
    this.userIdle.startWatching();
    this.userIdle.ping$.subscribe(() => {
      this.keepAliveFunction();
    });
    this.userIdle.onTimerStart().subscribe((count) => {
      if (count !== null) {
        this.modalService.exibirMensagem(
          'Sessão Expirada!',
          'Sua sessão será fechada em :  ' + (30 - count) + ' segundos',
          'Atualizar sessão'
        );
      }
    });
    this.userIdle.onTimeout().subscribe(() => {
      this.timedOut = true;
      this.logoutUsuarioModal();
    });
    this.isLogedSubject.next(true);
  }

  getUsuario(): Usuario {
    if (this.usuario == null) {
      this.usuario = this.cookieService.getObject('usuarioCadastro') as Usuario;
    }
    return this.usuario;
  }

  getUsername(): string {
    const usuario = this.getUsuario();
    let username = '';
    if (usuario !== null) {
      if (usuario.userData.tipoUsuario === 1) {
        username = usuario.userData.userAplicacao.username;
      }
      if (usuario.userData.tipoUsuario === 2) {
        username = usuario.userData.userExterno.username;
      }
      if (usuario.userData.tipoUsuario === 3) {
        username = usuario.userData.userInterno.username;
      }
    }
    return username;
  }

  tokenExpirado(): boolean {
    const usuario = this.cookieService.getObject('usuarioCadastro') as Usuario;
    if (usuario !== null && usuario !== undefined) {
      const now = new Date();
      const fimToken = new Date(usuario.data_expiracao);
      if (Number(fimToken.getTime()) < Number(now.getTime())) {
        console.log('passou no tokenexpirado e retornou ' + true);
        return true;
      }
      return false;
    }
    return null;
  }

  estaAutenticado(): boolean {
    if (this.tokenExpirado() === false) {
      const usuario = this.getUsuario();
      if (usuario !== null) {
        this.autenticado = true;
      } else {
        this.autenticado = false;
      }
      return this.autenticado;
    }
    return false;
  }

  renovarToken(refreshToken: string): Observable<Usuario> {
    const dados = new URLSearchParams();
    dados.append('grant_type', 'refresh_token');
    dados.append('refresh_token', refreshToken);
    const url: string = this.configuracao.api_autorizador + '/autoriza';
    return this.http.post<Usuario>(url, dados.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    });
  }

  isInRole(expectedRole: string, tipoUsuario: number): boolean {
    return (
      this.usuario.scope.includes(expectedRole) &&
      this.usuario.userData.tipoUsuario === tipoUsuario
    );
  }

  abrirModalLogin(): void {
    const loginModal = $('#loginModal');
    this.modalService.fecharPaginaModal();
    loginModal.removeClass('fechado');
    loginModal.addClass('abrirModal');
    $('#loginModalOverlay').removeClass('fechado');
  }

  fecharModalLogin(): void {
    $('#loginModal #chave').val('');
    $('#loginModal #senha').val('');
    const loginModal = $('#loginModal');
    loginModal.removeClass('abrirModal');
    loginModal.addClass('fechado');
    $('#loginModalOverlay').addClass('fechado');
  }

  erroCarregamento(): void {
    this.cookieService.putObject('usuarioCadastro', null, null);
    localStorage.setItem('access_token', '');
    this.autenticado = false;
    this.usuario = null;
    this.usuarioChange.next(null);
    localStorage.clear();
    sessionStorage.clear();
    this.userIdle.stopWatching();
    this.userIdle = null;
    this.isLogedSubject.next(false);
    this.router.navigate(['erro-carregamento']);
  }

  public igaSurveySave(survey: any): Observable<string> {
    // const url = this.getConfiguracao().api_validador + '/survey/csatSave';
    // const url = this.getConfiguracao().api_validador + '/survey/registrarNota';
    const url =
      this.getConfiguracao().api_validador + '/survey/registrarNotaIga';
    return this.http.post<string>(url, survey, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  public igaSurveyScore(): Observable<any> {
    const url = this.getConfiguracao().api_validador + '/survey/csatScore';
    return this.http.get<any>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  public igaSurveyData(): Observable<any> {
    const url = this.getConfiguracao().api_validador + '/survey/csatData';
    return this.http.get<any>(url, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  private extractData(res: Response): any {
    const body = res.json();
    return body || {};
  }

  private handleError(error: Response | any): any {
    console.log('Passou no Handle Error do GlobalService');
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(new Error(errMsg));
  }
}
