import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {GlobalService} from '../../service/global.service';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.css']
})
export class RodapeComponent implements OnInit, OnDestroy {

  isLogedSubscription: Subscription;
  isLoged: boolean;
  versao = environment.version;
  pesqSat = 0;

  constructor(
    public globalService: GlobalService
  ) {
  }

  ngOnInit(): void {

     this.isLogedSubscription = this.globalService.isLogedObservable().subscribe(retorno => {
      this.isLoged = retorno;
      if (this.isLoged) {
        this.mostraScore();
      }
    });
  }

  mostraScore(): void {
    this.globalService.igaSurveyScore().subscribe(retorno => {
      this.pesqSat = retorno.score;
    });
  }

  ngOnDestroy(): void {
    this.isLogedSubscription.unsubscribe();
  }

}
