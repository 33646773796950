<nav class="navbar navbar-expand-lg bg-secondary navbar-inverse navbar-dark fixed-top topo border-0 border-bottom border-warning">
  <a class="navbar-brand texto-menu" href="">
      <img alt="" class="logo-bb" src="./assets/imagens/logo.png"> <!-- Convênios -->
  </a>
  <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <!-- Ambiente logado -->
        <li *ngIf="this.globalService.ambienteLogado" class="nav-item dropdown active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Validador de Leiautes
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="https://www.bb.com.br/pbb/pagina-inicial/aplicativos-bb/leiautes#/" target="_blank">Manuais</a>
            <a class="dropdown-item" [routerLink]="['/faq']" target="_blank">FAQ Validador de Leiautes</a>
          </div>
        </li>
        <li *ngIf="isLoged" class="navbar-nav">
          <a (click)="selecionaMenu('logout')" class="nav-link" id="menulogout">Logout</a>
        </li>
      </ul>  

      <ul class="navbar-nav">
        <!-- Ambiente não logado -->
        <li *ngIf="this.globalService.ambienteLogado==false" class="nav-item dropdown active">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Validador de Leiautes
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="https://www.bb.com.br/pbb/pagina-inicial/aplicativos-bb/leiautes#/" target="_blank">Manuais</a>
            <a class="dropdown-item" [routerLink]="['/faq-externo']" target="_blank">FAQ Validador de Leiautes</a>
          </div>
        </li>
      </ul>
  </div>
</nav>


