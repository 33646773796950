<div class="col-avalie aberto">
  <div (click)="controleAbertura()" class="btn-avalie">
    <label *ngIf="this.globalService.ambienteLogado" class="star-small" [class.animacao] = "booleanAnimacao"></label>
    <label *ngIf="this.globalService.ambienteLogado==false" class="star-small" [class.animacao] = "booleanAnimacaoExterno"></label>
    <div class="d-none d-md-block d-lg-block d-xl-block">Deixe sua opinião
    </div>
    <div class="d-block d-md-none">Avalie</div>
  </div>
  <div [class.closed]="!booleanControleAbertura" [class.open]="booleanControleAbertura" class="content-avalie">
    <div class="content-itens">
      <div *ngIf="booleanAvaliableSuccess" class="avalie-aviso avaliable-success">Avaliação enviada com sucesso</div>
      <div *ngIf="booleanExibeComponentes && !booleanAvaliableSuccess" class="row justify-content-center">
        <div *ngIf="booleanExibeComponentes && !booleanAvaliableSuccess" class="stars">
          <form action="">
            <input (click)="clicouEstrela($event)" class="star star-5" #star5 id="star-5" name="star" type="radio"/>
            <label class="star star-5" for="star-5"></label>
            <input (click)="clicouEstrela($event)" class="star star-4" #star4 id="star-4" name="star" type="radio"/>
            <label class="star star-4" for="star-4"></label>
            <input (click)="clicouEstrela($event)" class="star star-3" #star3 id="star-3" name="star" type="radio"/>
            <label class="star star-3" for="star-3"></label>
            <input (click)="clicouEstrela($event)" class="star star-2" #star2 id="star-2" name="star" type="radio"/>
            <label class="star star-2" for="star-2"></label>
            <input (click)="clicouEstrela($event)" class="star star-1" #star1 id="star-1" name="star" type="radio"/>
            <label class="star star-1" for="star-1"></label></form>
        </div>
        <div *ngIf="booleanExibeComponentes && !booleanAvaliableSuccess" >
          <span id='close' (click)="controleAbertura()" style="margin: 7px; cursor: pointer; z-index: 10; font-weight:bold;" >x</span>
        </div>
      </div>
      <div *ngIf="avaliacao > 0 && avaliacao > 3 && booleanExibeComponentes && !booleanAvaliableSuccess" class="row">
        <textarea class="input-text-avalie" cols="33" id="input-avalie" maxlength="200" [(ngModel)]="comentario"
                  name="input-avalie" placeholder="Deixe aqui sua crítica ou sugestão:"
                  rows="4"></textarea>
      </div>
      <div *ngIf="avaliacao > 0 && avaliacao <= 3 && booleanExibeComponentes && !booleanAvaliableSuccess" class="row">
        <textarea class="input-text-avalie" cols="33" id="input-avalie" maxlength="200" [(ngModel)]="comentario"
                  name="input-avalie" placeholder="Deixe aqui sua crítica, sugestão, dúvidas ou relate o problema:"
                  rows="4"></textarea>
      </div>
      <div *ngIf="avaliacao > 0 && avaliacao <= 3 && booleanExibeComponentes && !booleanAvaliableSuccess" class="bad-avaliable">
        <div>
          <input class="input-text-emailTelefone" maxlength="38" type="text" [(ngModel)]="emailTelefone" placeholder="Deseja informar seu e-mail ou telefone?"/>
        </div>
      </div>
      <div *ngIf="booleanExibeComponentes && !booleanAvaliableSuccess" class="row footer-avalie justify-content-between">
        <div class="col-7"><span class="count-carcter">{{comentario.length}}/200</span></div>
        <div class="col-5">
          <button (click)="salvar()" [disabled]="(avaliacao <= 0 || !(comentario.trim().length > 0 || avaliacao > 3))" class="btn btn-secondary botaoEnviar" type="button">ENVIAR</button>
        </div>
      </div>
    </div>
  </div>
</div>
