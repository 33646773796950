import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {IntegerPipe} from '../pipes/integer.pipe';

/*
Diretiva que permite apenas a digitacao de numeros em determinado input, como MCI.
Tambem permite de Copy/Paste neste campo.
*/

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[integerDirective]'
})
export class IntegerDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private integerPipe: IntegerPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.el.value = this.integerPipe.transform(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value): void {
    this.el.value = this.integerPipe.parse(value); // opossite of transform
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value): void {
    this.el.value = this.integerPipe.transform(value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/[^0-9]/g, ''); // get a digit-only string
    this.el.value = pastedInput;
    const e = document.createEvent('UIEvent');
    e.initEvent('input', true, false);
    this.el.dispatchEvent(e);
    // document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const textData = event.dataTransfer
      .getData('text').replace(/[^0-9]/g, '');
    this.el.focus();
    this.el.value = textData;
    const e = document.createEvent('UIEvent');
    e.initEvent('input', true, false);
    this.el.dispatchEvent(e);
    //    document.execCommand('insertText', false, textData);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
    ) {
      return;  // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

}
