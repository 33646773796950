import {Pipe, PipeTransform} from '@angular/core';

/*
Pipe usada pela diretiva integerDirective
*/

@Pipe({
  name: 'integer'
})
export class IntegerPipe implements PipeTransform {

  transform(value: number | string): string {
    if (value === '' || value == null) {
      return null;
    }
    return value.toString();
  }

  parse(value: string): string {

    if (value === '' || value == null) {
      return null;
    }
    return value;
  }

}
