import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalService} from '../../service/modal.service';
import {AutenticacaoApiService} from '../../service/api/autenticacao.api';
import {GlobalService} from '../../service/global.service';
import { MatomoTracker } from '@ngx-matomo/tracker';

/*
Login para usuario impessoal VAN e usuario interno BB.
*/


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    mensagens: string;
    erroChave: boolean;
    erroSenha: boolean;

    chave = new FormControl('', [Validators.required]);
    senha = new FormControl('', []);
    certificado = new FormControl(false, []);

    loginForm: FormGroup = this.formBuilder.group({
        chave: this.chave,
        senha: this.senha,
        certificado: this.certificado
    });

    constructor(private formBuilder: FormBuilder,
                public modalService: ModalService,
                private router: Router,
                private autenticacaoApiService: AutenticacaoApiService,
                public globalService: GlobalService,
                private readonly matomoTracker: MatomoTracker) {
    }

    ngOnInit() {
        this.modalService.fecharPaginaModal();
    }

    loginUsuario() {

        this.chave.value === '' ? this.erroChave = true : this.erroChave = false;
        if (this.certificado.value === false) {
            this.senha.value === '' ? this.erroSenha = true : this.erroSenha = false;
        } else {
            this.erroSenha = false;
        }

        if (this.erroChave || this.erroSenha) {
            return null;
        }

        const username = this.chave.value;
        const password = this.senha.value;
        const scope = this.globalService.getConfiguracao().api_escopo;
        this.mensagens = null;
        this.autenticacaoApiService.loginUsuario(username, password, scope, this.certificado.value)
            .subscribe(
                (usuario) => {
                    this.globalService.loginUsuario(usuario);
                    this.router.navigate(['/home']);
                    // Tracker Matomo
                    console.log('Fazendo tracking pelo Matomo...');
                    this.matomoTracker.setUserId(
                        usuario.userData.userInterno.uor.toString()
                    );
                    this.matomoTracker.setCustomVariable(
                        1,
                        'uor',
                        usuario.userData.userInterno.uor.toString(),
                        'visit'
                    );
                    this.matomoTracker.setCustomVariable(
                        2,
                        'chave',
                        usuario.userData.userInterno.username,
                        'visit'
                    );
                    this.matomoTracker.enableCrossDomainLinking();
                    this.matomoTracker.trackAllContentImpressions();
                    this.matomoTracker.trackPageView();
                },
                (erro) => {
                    console.error('Erro autenticação');
                    console.log(erro);
                    if (erro.status === 400 || erro.status === 500) {
                        this.mensagens = erro.error.error_description;
                    } else if (erro.status === 401) {
                        this.mensagens = 'Usuário inválido ou senha incorreta.';
                    } else if (erro.status === 403) {
                        this.mensagens = 'Solicitar ACESSO-11-01 Aplicativo GMT';
                    } else if (erro.status === 404) {
                        this.mensagens = 'Servidor não respondendo';
                    } else {
                        this.mensagens = 'Falha no login';
                    }
                },
                () => {
                }
            );
    }

    pulaProximoCampo(event: any, focusable: any) {
        let chave = event.target.value;
        chave = chave.toLowerCase();
        if (chave.length === 8 &&
            (chave.charAt(0) === 'f' ||
                chave.charAt(0) === 'j' ||
                chave.charAt(0) === 'c') && /^\d+$/.test(chave.substr(1, 7))) {
            focusable.focus();
        }
    }

    certificadoCheck() {
        if (this.certificado.value === true) {
            this.senha.disable();
        } else {
            this.senha.enable();
        }
    }

}
