import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../service/global.service';
import * as moment from 'moment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { data } from 'jquery';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css'],
})
export class SurveyComponent implements OnInit {
  booleanControleAbertura = false;
  booleanExibeComponentes = false;
  booleanAvaliableSuccess = false;
  booleanAlterouControle = false;
  faTimes = faTimes;
  emailTelefone = '';

  @Input()
  booleanAnimacao = false;
  booleanAnimacaoExterno = false;

  @ViewChild('star5') star5: ElementRef;
  @ViewChild('star4') star4: ElementRef;
  @ViewChild('star3') star3: ElementRef;
  @ViewChild('star2') star2: ElementRef;
  @ViewChild('star1') star1: ElementRef;

  comentario = '';
  avaliacao = 0;
  pesqSat: any;
  pesqDados: any;

  constructor(public globalService: GlobalService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.booleanAnimacaoExterno = true;
    }, 30000);
  }

  fechar(): void {
    this.limpar();
  }

  limpar(): void {
    this.avaliacao = 0;
    this.comentario = '';
    if (this.star5 !== undefined) {
      this.star5.nativeElement.checked = false;
      this.star4.nativeElement.checked = false;
      this.star3.nativeElement.checked = false;
      this.star2.nativeElement.checked = false;
      this.star1.nativeElement.checked = false;
    }
  }

  salvar(): void {
    this.booleanAnimacao = false;
    this.pesqDados = {
      // user_id: this.globalService.getUsername() !== '' ? this.globalService.getUsername() : 'anonimo' ,
      // campaign_id: 'validador_leiaute',
      // batch_id: 0,
      // score: this.avaliacao,
      // interaction_id: '',
      // answer_date: moment(),
      // answer_reason: this.avaliacao > 3? this.comentario : (this.comentario + '| ' + this.emailTelefone)

      attendant_name: 'Validador',
      user_name: 'anonimo',
      email: 'anonimo@anonimo.com.br',
      iteration_code: moment().format('x'),
      sent_at: moment().format('yyyy-MM-DD HH:mm:ss'),
      answered_at: moment().format('yyyy-MM-DD HH:mm:ss'),
      value: this.avaliacao,
      observation:
        this.comentario !== ''
          ? this.comentario + '| ' + this.emailTelefone
          : 'Nota maior que 4 é opcional',
      solved: 'S',
      api_token: 'vCJ3kd1tz1NLvrAc_ASmT0JYpXemUyo0',

      // 'attendant_name': 'F3161256',
      // 'user_name': 'Usuario',
      // 'email': 'mail2@bb.com.br',
      // 'iteration_code': 'A1234567895',
      // 'sent_at': '2022-12-15 10:00:30',
      // 'answered_at': '2022-12-15 11:00:30',
      // 'value': 5,
      // 'observation': 'Teste 16/12/2022',
      // 'solved': 'S',
      // 'api_token':'vCJ3kd1tz1NLvrAc_ASmT0JYpXemUyo0'
    };
    this.globalService.igaSurveySave(this.pesqDados).subscribe(
      (retorno) => {
        this.pesqSat = retorno;
        this.limpar();
        this.booleanAvaliableSuccess = true;
        this.booleanAlterouControle = false;
        setTimeout(() => {
          if (!this.booleanAlterouControle) {
            this.booleanAvaliableSuccess = false;
            this.booleanControleAbertura = false;
            this.booleanExibeComponentes = false;
          }
        }, 1000);
      },
      (error) => {
        console.log(error);
        this.limpar();
      },
      () => {}
    );
  }

  clicouEstrela(event: any): void {
    switch (event.target.id) {
      case 'star-1': {
        this.avaliacao = 1;
        break;
      }
      case 'star-2': {
        this.avaliacao = 2;
        break;
      }
      case 'star-3': {
        this.avaliacao = 3;
        break;
      }
      case 'star-4': {
        this.avaliacao = 4;
        break;
      }
      case 'star-5': {
        this.avaliacao = 5;
        break;
      }
    }
  }

  controleAbertura(): void {
    this.booleanAlterouControle = true;
    this.booleanAvaliableSuccess = false;
    this.booleanControleAbertura = !this.booleanControleAbertura;
    if (!this.booleanExibeComponentes) {
      setTimeout(() => {
        this.booleanExibeComponentes = !this.booleanExibeComponentes;
      }, 450);
    } else {
      this.booleanExibeComponentes = !this.booleanExibeComponentes;
    }
    this.limpar();
  }
}
