import {Injectable} from '@angular/core';
import {Usuario} from '../../model/usuario';
import {GlobalService} from '../global.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoApiService {

  constructor(private http: HttpClient, private globalService: GlobalService) {
  }

  loginUsuario(chave: string, senha: string, escopo: string, certificado: boolean): Observable<Usuario> {
    let url: string;
    const dados = new URLSearchParams();
    dados.append('grant_type', 'password');
    dados.append('scope', escopo);
    dados.append('username', chave.toLowerCase());
    dados.append('password', senha);

    if (certificado === true) {
      url = this.globalService.getConfiguracao().api_autorizador_certificado + '/autoriza';
    } else {
      url = this.globalService.getConfiguracao().api_autorizador + '/autoriza';
    }
    return this.http.post<Usuario>(url, dados.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    });
  }

  alterarSenha(senhaAntiga: string, senhaNova: string, usuario: string): Observable<Usuario> {
    let url: string;
    const dados = {senhaAntiga, senhaNova};
    url = this.globalService.getConfiguracao().api_autorizador + '/usuario/senha/' + usuario;
    return this.http.post<Usuario>(url, dados, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    });
  }
}
