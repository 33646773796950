import { Encoding } from './../components/home/home.component';
import { Injectable, Input } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { isTypeAliasDeclaration } from 'typescript';
import { RespostaAnalise } from '../model/models';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor() {}

  @Input()
  set encoding(encoding: string) {
    this.encoding = encoding;
  }

  generatePdf(respostaAnalise: RespostaAnalise, somenteErros: boolean): void {
    const dd = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: [20, 20, 20, 20],
      content: [],
      styles: {
        tableStyle: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        titulo: {
          bold: true,
          fontSize: 12,
          color: '#035AA5',
        },
        textoSolRec: {
          color: '#035AA5',
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
      images: {
        exclamacao_triangulo:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsSAAALEgHS3X78AAACBElEQVRoge2Z303DMBDGrxbvZYOWAayWCQrveWCDwgYdoSN0AxjC77ABlTIAZYOygIuCTqgpju3z3ZFWyk/KQ/6eL599uU8ZHQ4HuGTMRY9+SOAMUE3AO7tuNs0YaovYOzsFgC3uzk1V7zTiaCrQvPkxbmoqqCjgnb0DgNeTw/emqt+kY2kpsMk8xkY8Ae/sIwDMAqdmeE4U0Snknb0GgB3O+xBfADA1Vb2XiimtwCoyeMBzK8mAYgpg2fzIvPxGqqxKKvCidG0UkQSwbC4ItyzwHjZSCpS8UREV2Al4Z5tFOSm4dYL3smAt4oyymYJdVrkKbBiDbxh3fLWzKVbAOzsHgHdO8CNuTVVvs68+4ooRNPrmTFWPjve9s7E31TyrqCoVTSHv7AOxbKZY4DPJkBPAhavRWW7w2SRKFCgtmykmJX0SKQHsd0SbsRNWGCMbqgJrZtlMQbaf2Qlg77KUHnGAJaVPoiigYgm5sbI+ZGgFn7mjIvJkqjrZ8CUTEOh3Ssnqk3KmUMomapFlP6MKEG2iFlH7meqFik0HsRdKjaGzKnVOoQKbqEXUfsbWgJjxFqBzLMEEGDZRi077+WcR91g2UwTLakgBrk3UImg/WwoI20QtWvbzVIH/7HdKaY3xNwEFm6hFy37+TCFcuNszqzwxPvG/2374U983QwJ9MyTQKwDwDbPTulkiUkFSAAAAAElFTkSuQmCC',
        x_circle:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABLElEQVQ4ja2Vu3HDMAyGf/PUO626ZAJXpllqlGiCaANHG8gTyKOo1KnTBHFltskEyoFBfDRI+iH7LwniE4iXFtM0Qcoa/QqgAlAAWAnzCKAD0OT9cJC+Z0Br9AuATwAfwVfi2tH9vB++AyDDukhE10QRF/9Q9SAM7NMx4w/Iz5wD86HEwOK4WVMBvsSFH0o6gG0CUHPRluL8TbFBiipIXywjtpJtTcRWKW4Nqa01+j3vh72AEmxPtkT0RXYhd601GgxwBx6sTfisKIdhZ4dPpEhxBeakLhnnKLslOo7Mf34yyow7PZbHUuZM5DQGHRVPiFSdKEDrVb+O+HVPb2y3HKzRzR0bJqVd3g+VP8vjAzDydbPsgLx6aGLmQMP1JaC0NG8V3T3BSM/9BQD4BW/bk9MByEL7AAAAAElFTkSuQmCC',
        file_error:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA6UlEQVQ4jb2UMQ7CMAxFXcSeObfo0jRjbkI5CXAS4CZ0izL1CEzNzAlSuThVVAGpi8BSJctxXv37rRZ9XR0B4ADL4yqta951b5iwBwDsvFaXT0BOmByUBZTWdTkod8IslA3MQVcBX0FjfcuBeK1Crmf1hH8DsiQn0QIAGnGnUvkNcC+tm+/fLSZcySPMa9V4raapMMca5vhzyDpH0UrrDF0807qYZEKBL+RIjjI7golEqqBax5E8GjBbaJHADJ79ZA9PC3tHE8iM+M1wskk+nhUhLPXkGTlTAIHcp6+rpq+rMt7DHGshBBgAbJiP+wlH4oIAAAAASUVORK5CYII=',
      },
      footer: (currentPage, pageCount) => {
        return [
          {
            text: currentPage.toString() + ' de ' + pageCount,
            alignment: 'center',
          },
        ];
      },
    };
    dd.content.push({
      columns: [
        {
          text: 'Análise do arquivo: ',
          style: 'titulo',
          width: 393,
        },
        {
          image: 'file_error',
          width: 12,
          height: 12,
        },
        {
          margin: [5, 0, 0, 0],
          width: 'auto',
          text: ' Erros de estrutura: Impedem o processamento',
        },
      ],
    });
    dd.content.push({
      columns: [
        {
          text: respostaAnalise.nomeArquivo,
          width: 393,
        },
        {
          image: 'x_circle',
          width: 12,
          height: 12,
        },
        {
          margin: [5, 0, 0, 0],
          width: 'auto',
          text: ' Erros de Campo: impedem o processamento',
        },
      ],
    });
    dd.content.push({
      columns: [
        {
          text: '',
          width: 393,
        },
        {
          image: 'exclamacao_triangulo',
          width: 12,
          height: 12,
        },
        {
          margin: [5, 0, 0, 0],
          width: 'auto',
          text: ' Alertas: Recomendação de ajuste (não impedem o processamento)',
        },
      ],
    });

    for (const registro of respostaAnalise.registrosAnalisados) {
      const tabela = {
        style: 'tableStyle',
        table: {
          // widths: [60, 100, '*', 130, 170],
          widths: [60, 150, 150, '*', 200],
          headerRows: 2,
          body: [
            [
              'LINHA ' + registro.numeroLinha,
              { colSpan: 4, text: registro.tipoRegistro },
              {},
              {},
              {},
            ],
            [
              'POSIÇÃO',
              'NOME DO CAMPO',
              'CONTEÚDO OBSERVADO',
              'ERRO/ALERTA',
              'SOLUÇÃO/RECOMENDAÇÃO',
            ],
          ],
        },
        layout: {
          defaultBorder: false,
          fillColor: (rowIndex, node, columnIndex) => {
            if (rowIndex === 0) {
              return '#B5E2F7';
            } else if (rowIndex === 1) {
              return '#E3F4FC';
            } else {
              return rowIndex % 2 === 0 ? '#F5F5F5' : '#FFFFFF';
            }
          },
        },
      };
      if (registro.possuiErro) {
        const errosAlertasRegistro = new Array<any>();
        const solucoesRegistro = new Array<any>();
        for (const mensagem of registro.mensagens) {
          if (mensagem.tipo === 'ERR') {
            const valor = {
              columns: [
                {
                  image: 'file_error',
                  width: 12,
                  height: 12,
                },
                {
                  margin: [5, 0, 0, 0],
                  width: 'auto',
                  text: mensagem.mensagem,
                },
              ],
            };
            errosAlertasRegistro.push(valor);
          }
          if (mensagem.tipo === 'WAR') {
            const valor = {
              columns: [
                {
                  image: 'exclamacao_triangulo',
                  width: 12,
                  height: 12,
                },
                {
                  margin: [5, 0, 0, 0],
                  width: 'auto',
                  text: mensagem.mensagem,
                },
              ],
            };
            errosAlertasRegistro.push(valor);
          }
          // Ajuste para mensagem de codificação do arquivo.
          if (mensagem.tipo === 'SOL') {
            if (this.encoding === 'ANSI') {
              const valor = { text: mensagem.mensagem, style: 'textoSolRec' };
              solucoesRegistro.push(valor);
            } else {
              if (
                mensagem.mensagem ===
                  'Adequar a linha para conter 240 caracteres.' ||
                mensagem.mensagem ===
                  'Adequar a linha para conter 400 caracteres.' ||
                mensagem.mensagem ===
                  'Adequar a linha para conter 750 caracteres.' ||
                mensagem.mensagem ===
                  'Adequar a linha para conter 150 caracteres.' ||
                mensagem.mensagem ===
                  'Adequar a linha para conter 300 caracteres.'
              ) {
                const valor = {
                  text:
                    mensagem.mensagem +
                    ' Ajustar a codificação do arquivo para ANSI.',
                  style: 'textoSolRec',
                };
                solucoesRegistro.push(valor);
              } else {
                const valor = { text: mensagem.mensagem, style: 'textoSolRec' };
                solucoesRegistro.push(valor);
              }
            }
          }
        }
        if (errosAlertasRegistro.length > 0 || solucoesRegistro.length > 0) {
          const linhaErroRegistro = new Array<any>();
          linhaErroRegistro.push('');
          linhaErroRegistro.push('');
          linhaErroRegistro.push('');
          linhaErroRegistro.push(errosAlertasRegistro);
          linhaErroRegistro.push(solucoesRegistro);
          tabela.table.body.push(linhaErroRegistro);
        }
      }

      for (const dado of registro.dadosAnalisados) {
        const linha = new Array<any>();
        linha.push(dado.posicao);
        linha.push(dado.nomeCampo);
        linha.push(dado.conteudoObservado);
        const errosAlertas = new Array<any>();
        const solucoes = new Array<any>();
        for (const mensagem of dado.mensagens) {
          if (mensagem.tipo === 'ERR') {
            const valor = {
              columns: [
                {
                  image: 'x_circle',
                  width: 12,
                  height: 12,
                },
                {
                  margin: [5, 0, 0, 0],
                  width: 'auto',
                  text: mensagem.mensagem,
                },
              ],
            };
            errosAlertas.push(valor);
          }
          if (mensagem.tipo === 'WAR') {
            const valor = {
              columns: [
                {
                  image: 'exclamacao_triangulo',
                  width: 12,
                  height: 12,
                },
                {
                  margin: [5, 0, 0, 0],
                  width: 'auto',
                  text: mensagem.mensagem,
                },
              ],
            };
            errosAlertas.push(valor);
          }
          if (mensagem.tipo === 'SOL') {
            const valor = { text: mensagem.mensagem, style: 'textoSolRec' };
            solucoes.push(valor);
          }
        }
        linha.push(errosAlertas);
        linha.push(solucoes);
        if (somenteErros) {
          if (dado.possuiErro) {
            tabela.table.body.push(linha);
          }
        } else {
          tabela.table.body.push(linha);
        }
      }
      if (somenteErros) {
        if (registro.possuiErro) {
          dd.content.push(tabela);
        }
      } else {
        dd.content.push(tabela);
      }
    }

    pdfMake.createPdf(dd).download();
  }
}
