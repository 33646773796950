export class ErroAPI {
  httpStatus: number;
  httpText: number;
  erro: string;
  mensagem: string;
}

export class RespostaBasica{
  codigo: string;
  mensagem: string;
}

export class DataTablesResponse<T> {
  data: T[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export class Sort {
  direction = '';
  property = '';
  ignoreCase = true;
  nullHandling = '';
  descending = false;
  ascending = true;
}

export class Pagina<T> {
  content: T[];
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort[];
  numberOfElements: number;
  first: boolean;
}

export class Ordenacao {
  sort: string;
  order: string;
}

export class Pageable {
  sort: SortPage;
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

export interface SortPage {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Page<T> {
  content: T[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: SortPage;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export class PatchAtualizacaoProtocolo{
  acao: string;
  mensagem: string;
  visivelVan: boolean;
}
