<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-lg-12 col-sm-12">
            <p *ngIf="message" class="text-dark">{{message}}</p>
        </div>
    </div>
    <div class=" row justify-content-center ">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <button (click)="confirma()" class=" btn btn-primary "
                    id=" btnSim" style=" min-width: 40px;" type="button">
                <i class=" fa fa-check "></i>
                Sim
            </button>
        </div>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <button (click)="naoConfima()" class=" btn btn-primary "
                    id=" btnNao" style=" min-width: 40px;" type="button">
                <i class=" fa fa-close "></i>
                Não
            </button>
        </div>
    </div>
</div>
