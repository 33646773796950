import { element } from 'protractor';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GlobalService } from '../../service/global.service';
import { Router } from '@angular/router';
import { DataTransportService } from '../../service/data-transport.service';
import { ValidadorService } from '../../service/api/validador.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RespostaAnalise } from '../../model/models';
import { Observable, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PdfService } from '../../service/pdf.service';
import { PdfPadraoApjService } from '../../service/pdf-padrao-apj.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TypePredicateKind } from 'typescript';

declare const require: any;
export const Encoding = require('encoding-japanese');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnDestroy, OnInit, AfterViewInit {
  form: FormGroup;
  listaLeiaute: Array<any>;
  respostaAnalise: RespostaAnalise;
  respostaAnaliseNaoNormalizada: any[] = [];
  respostaAnaliseNaoNormalizadaErros: any[] = [];
  respostaAnaliseFinal: any[] = [];
  exibeErrosSomente = true;
  nomeArquivoSelecionado = 'Nenhum arquivo selecionado';
  qtdErros = 0;
  qtdLinhas = 0;
  qtdAlertas = 0;
  encoding: string;
  leiaute: any;
  leiauteLocalizado: boolean;

  isLogedSubscription: Subscription;
  isLoged: boolean;
  booleanAnimacao = false;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    public globalService: GlobalService,
    public fb: FormBuilder,
    public router: Router,
    public dataTransportService: DataTransportService,
    public validadorService: ValidadorService,
    private pdfService: PdfService,
    private pdfPadraoApjService: PdfPadraoApjService
  ) {
    this.form = this.fb.group({
      leiaute: [this.leiaute],
      arquivo: [null],
    });
  }

  ngOnInit(): void {
    this.validadorService.obterLeiautes().subscribe(
      (resposta) => {
        this.listaLeiaute = resposta;
      },
      (error) => {
        alert('Erro ao obter lista de leiautes');
      }
    );

    this.isLogedSubscription = this.globalService
      .isLogedObservable()
      .subscribe((retorno) => {
        this.isLoged = retorno;
        if (this.isLoged) {
          this.carregaDadosSurvey();
        }
      });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [
        [5, 10, 15, 20, 50, -1],
        [5, 10, 15, 20, 50, 'Todos'],
      ],
      processing: true,
      language: {
        emptyTable: 'Nenhum registro encontrado',
        processing: 'Carregando...',
        lengthMenu: 'Exibir _MENU_ registros',
        zeroRecords: 'Não foram encontrados resultados',
        info: '_START_ - _END_ de _TOTAL_',
        infoEmpty: '0 registros',
        infoFiltered: '(filtrado de _MAX_ registros no total)',
        infoPostFix: '',
        search: 'Pesquisar:',
        url: '',
        paginate: {
          first: 'PRIMEIRO',
          previous: 'ANTERIOR',
          next: 'PRÓXIMO',
          last: 'ÚLTIMO',
        },
        aria: {
          sortAscending: ': Ordenar colunas de forma ascendente',
          sortDescending: ': Ordenar colunas de forma descendente',
        },
      },
      columns: [
        { orderable: false, visible: false },
        { orderable: false, width: '10%' },
        { orderable: false, width: '22%' },
        { orderable: false, width: '24%' },
        { orderable: false, width: '22%' },
        { orderable: false, width: '22%' },
      ],
      order: [[0, 'asc']],
      buttons: [
        'copyHtml5',
        'excelHtml5',
        'csvHtml5',
        {
          action: (e, dt, node, config) => {
            // this.pdfService.generatePdf(this.respostaAnalise, this.exibeErrosSomente);
            this.pdfPadraoApjService.generatePdf(
              this.respostaAnalise,
              this.exibeErrosSomente,
              this.qtdErros,
              this.qtdAlertas,
              this.encoding
            );
          },
        },
      ],
    };
    this.formChanges();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  onFileSelect(event): void {
    this.respostaAnalise = null;
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      arquivo: file,
    });
    this.form.get('arquivo').updateValueAndValidity();
    this.nomeArquivoSelecionado = this.form.get('arquivo').value.name;
    this.detectEncoding(event.currentTarget.files[0]).subscribe((encoding) => {
      // console.log('File encoding is: ' + encoding);
      this.encoding = encoding;

      $('#alerta-encoding-alerta').hide();
      if (encoding != null && encoding !== 'ASCII') {
        if (file.size > 0 && file.size < 1500000) {
          $('#alerta-encoding-alerta').show();
        } else {
          $('#alerta-arquivo-vazio').show();
        }
      } else {
        $('#alerta-encoding-alerta').hide();
      }

      $('#alerta-arquivo-tamanho-excedido').hide();
      if (encoding != null) {
        if (file.size > 1499999) {
          $('#alerta-arquivo-tamanho-excedido').show();
          this.listaLeiaute = null;
        }
      }
    });
    this.identificaLeiaute(file);
  }

  selecionaMenu(menuSelecionado: string): void {
    switch (menuSelecionado) {
      case 'logout': {
        this.globalService.logoutUsuarioSair();
        break;
      }
      default: {
        console.log('default case do seleciona menu');
        break;
      }
    }
  }

  analisar(): void {
    const formData: any = new FormData();
    formData.append('file', this.form.get('arquivo').value);
    this.validadorService
      .validarArquivo(this.leiaute.nomeLeiaute, formData)
      .subscribe(
        (resposta) => {
          this.respostaAnalise = resposta;
          this.respostaAnaliseNaoNormalizada = new Array<any>();
          this.respostaAnaliseNaoNormalizadaErros = new Array<any>();
          this.respostaAnaliseFinal = [];
          this.qtdErros = 0;
          this.qtdLinhas = 0;
          this.qtdAlertas = 0;
          this.exibeErrosSomente = true;
          let index = 0;
          $('#alerta-mensagem-piloto').hide();
          for (const registroAnalisado of this.respostaAnalise
            .registrosAnalisados) {
            const regAnl = {
              indice: index++,
              tipoRegistro: registroAnalisado.tipoRegistro,
              numeroLinha: registroAnalisado.numeroLinha,
              nomeCampo: '-',
              posicao: '-',
              conteudoObservado: '-',
              possuiErro: registroAnalisado.possuiErro,
              observacoes: registroAnalisado.mensagens,
              tipoCampo: 'R',
            };
            this.qtdLinhas++;
            if (registroAnalisado.possuiErro) {
              for (const msg of registroAnalisado.mensagens) {
                // if (msg.tipo === 'ERR' || msg.tipo === 'WAR') {
                if (msg.tipo === 'ERR') {
                  this.qtdErros++;
                }
                if (msg.tipo === 'WAR') {
                  this.qtdAlertas++;
                }
              }
              this.respostaAnaliseNaoNormalizadaErros.push(regAnl);
            }
            this.respostaAnaliseNaoNormalizada.push(regAnl);

            for (const dadoAnalisado of registroAnalisado.dadosAnalisados) {
              const dadoAnl = {
                indice: index++,
                tipoRegistro: registroAnalisado.tipoRegistro,
                numeroLinha: registroAnalisado.numeroLinha,
                nomeCampo: dadoAnalisado.nomeCampo,
                posicao: dadoAnalisado.posicao,
                conteudoObservado: dadoAnalisado.conteudoObservado,
                possuiErro: dadoAnalisado.possuiErro,
                observacoes: dadoAnalisado.mensagens,
                tipoCampo: 'D',
              };
              if (dadoAnalisado.possuiErro) {
                for (const msg of dadoAnalisado.mensagens) {
                  // if (msg.tipo === 'ERR' || msg.tipo === 'WAR') {
                  if (msg.tipo === 'ERR') {
                    this.qtdErros++;
                  }
                  if (msg.tipo === 'WAR') {
                    this.qtdAlertas++;
                  }
                }
                this.respostaAnaliseNaoNormalizadaErros.push(dadoAnl);
              }
              this.respostaAnaliseNaoNormalizada.push(dadoAnl);
            }
          }
          this.filtrar();
          this.form.patchValue({
            leiaute: '',
          });
          this.nomeArquivoSelecionado = 'Nenhum arquivo selecionado';

          $('#alerta-arquivo-sucesso').hide();
          if (this.qtdErros === 0 && this.qtdAlertas === 0) {
            if (this.qtdLinhas === 0) {
              $('#alerta-arquivo-vazio').show();
              $('#alerta-encoding-alerta').hide();
              this.respostaAnalise = null;
            } else {
              $('#alerta-arquivo-sucesso').show();
              $('#alerta-arquivo-vazio').hide();
            }
          }
          if (this.qtdErros > 0) {
            $('#alerta-arquivo-erro').show();
          }
          if (this.qtdAlertas > 0) {
            $('#alerta-arquivo-warning').show();
          }

          $('#alerta-arquivo-pix').hide();
          if (
            this.respostaAnalise?.formato === 'CNAB750 - Pix Versão 02' ||
            this.respostaAnalise?.formaLancamentoPGT?.search(/Lote: 45/) >= 0 ||
            this.respostaAnalise?.formaLancamentoPGT?.search(/Lote: 47/) >= 0
          ) {
            $('#alerta-arquivo-pix').show();
          }

          $('#alerta-arquivo-retorno').hide();
          if (this.getValorAtributo('Código da Remessa / Retorno') === '2') {
            $('#alerta-arquivo-retorno').show();
            this.respostaAnalise = null;
            this.respostaAnaliseNaoNormalizada = [];
            this.respostaAnaliseNaoNormalizadaErros = [];
            this.respostaAnaliseFinal = [];
            $('#alerta-leiaute-sucesso').hide();
            $('#alerta-leiaute-error').hide();
            $('#alerta-arquivo-sucesso').hide();
            $('#alerta-arquivo-erro').hide();
            $('#alerta-arquivo-warning').hide();
            $('#alerta-arquivo-vazio').hide();
          }

          /*         $('#alerta-arquivo-csh').hide();
        if (this.getValorAtributo('Código da Remessa / Retorno') === '3') {
          $('#alerta-arquivo-csh').show();
          this.respostaAnalise = null;
          this.respostaAnaliseNaoNormalizada = [];
          this.respostaAnaliseNaoNormalizadaErros = [];
          this.respostaAnaliseFinal = [];
          $('#alerta-leiaute-sucesso').hide();
          $('#alerta-leiaute-error').hide();
          $('#alerta-arquivo-sucesso').hide();
          $('#alerta-arquivo-erro').hide();
          $('#alerta-arquivo-warning').hide();
          $('#alerta-arquivo-vazio').hide();
        } */
        },
        (error) => {
          this.respostaAnalise = null;
          this.respostaAnaliseNaoNormalizada = [];
          this.respostaAnaliseNaoNormalizadaErros = [];
          this.respostaAnaliseFinal = [];
          alert(error);
        }
      );
  }

  filtrar(): void {
    this.respostaAnaliseFinal = new Array<any>();
    if (this.exibeErrosSomente) {
      this.respostaAnaliseFinal = this.respostaAnaliseNaoNormalizadaErros;
    } else {
      this.respostaAnaliseFinal = this.respostaAnaliseNaoNormalizada;
    }
    this.rerender();
  }

  gerarCSV(): void {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.buttons('2').trigger();
    });
  }

  gerarXLS(): void {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.buttons('1').trigger();
    });
  }

  geraPDF(): void {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.buttons('3').trigger();
    });
  }

  copiarTXT(): void {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.buttons('0').trigger();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  identificaLeiaute(file: File): void {
    // $('.alert').hide();
    $('#alerta-leiaute-sucesso').hide();
    $('#alerta-leiaute-error').hide();
    $('#alerta-arquivo-sucesso').hide();
    $('#alerta-arquivo-erro').hide();
    $('#alerta-arquivo-warning').hide();
    $('#alerta-arquivo-vazio').hide();

    this.leiaute = null;
    this.modelChanges();
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      const arquivoLido: any = reader.result;
      const lines = arquivoLido.split(/\n/);
      for (const leiaute of this.listaLeiaute) {
        if (leiaute.listaRegras !== null) {
          for (const listaRegras of leiaute.listaRegras) {
            let encontrou = true;
            for (const validacao of listaRegras) {
              if (
                !(
                  lines[validacao.linha].substring(
                    validacao.posicaoInicial,
                    validacao.posicaoFinal
                  ) === validacao.valorComparacao
                )
              ) {
                encontrou = false;
              }
            }
            if (encontrou) {
              this.leiaute = leiaute;
            }
          }
        }
      }
      this.modelChanges();
      if (this.leiaute === null) {
        this.leiauteLocalizado = false;
        $('#alerta-leiaute-error').show();
      } else {
        this.leiauteLocalizado = true;
        if (file.size < 1500000) {
          $('#alerta-leiaute-sucesso').show();
        }
      }
    };
  }

  fechaAlerta(id: string): void {
    $('#' + id).hide();
  }

  formChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      this.leiaute = val.leiaute;
      $('#alerta-leiaute-sucesso').hide();
      $('#alerta-leiaute-error').hide();
    });
  }

  modelChanges(): void {
    this.form.patchValue({
      leiaute: this.leiaute,
    });
  }

  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== null && optionTwo !== null) {
      return optionOne.nomeLeiaute === optionTwo.nomeLeiaute;
    } else {
      return false;
    }
  }

  detectEncoding(file): Observable<string> {
    const result = new Subject<string>();

    const reader = new FileReader();
    reader.onload = (e) => {
      const codes = new Uint8Array(e.target.result as ArrayBuffer);
      const detectedEncoding = Encoding.detect(codes);
      result.next(detectedEncoding);
    };
    reader.readAsArrayBuffer(file);

    return result.asObservable();
  }

  carregaDadosSurvey() {
    this.globalService.igaSurveyData().subscribe((retorno) => {
      // filtra os dados retornados para verificar se o usuario ja respondeu a pesquisa nos ultimos 30 dias
      const dataAtual = moment().subtract(30, 'days');
      const dadosPesqSatFiltrado = retorno.data.filter((dado) => {
        const dataPesq = moment(dado.answer_date);
        return (
          dado.user_id.toUpperCase() ===
            this.globalService.getUsername().toUpperCase() &&
          dataPesq.isAfter(dataAtual)
        );
      });
      if (dadosPesqSatFiltrado.length === 0) {
        this.booleanAnimacao = true;
      } else {
        this.booleanAnimacao = false;
      }
    });
  }

  getValorAtributo(nomeAtributo: string) {
    if (this.respostaAnalise?.listaAtributosArquivo != null) {
      for (const atributo of this.respostaAnalise.listaAtributosArquivo) {
        if (atributo.nomeAtributo === nomeAtributo) {
          return atributo.valorAtributo;
        }
      }
    }
    return '';
  }
}
