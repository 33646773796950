import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-externo',
  templateUrl: './faq-externo.component.html',
  styleUrls: ['./faq-externo.component.css']
})
export class FaqExternoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
