import {Injectable} from '@angular/core';
import {ErroAPI} from '../../model/geral';
import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class UtilApi {

  constructor(private http: HttpClient,
              private globalService: GlobalService
  ) {
  }

  public trataErro(error: any): Observable<ErroAPI> {
    const erroApi = new ErroAPI();
    erroApi.httpStatus = error.status;
    erroApi.httpText = error.statusText;
    const json = error.json();
    if (json != null) {
      erroApi.erro = json.erro;
      erroApi.mensagem = json.mensagem;
    }
    return throwError(erroApi);
  }

  invocacaoRemotaComRetry(repetitions: number, timeout: number, objeto: any, funcao: () => any): any {
    if (repetitions > 0) {
      try {
        return funcao.call(objeto);
      } catch (error) {
        setTimeout(() => {
          return this.invocacaoRemotaComRetry(repetitions - 1, timeout, objeto, funcao);
        }, 1000);
      }
    } else {
      return null;
    }
  }

  invocacaoRemotaComCondicao(repetitions: number, timeout: number, objeto: any, funcao: () => any, condicao: boolean): any {
    if (repetitions > 0) {
      try {
        console.log(repetitions);
        if (condicao) {
          console.log(condicao);
          funcao.call(objeto);
          setTimeout(() => {
            return this.invocacaoRemotaComCondicao(repetitions - 1, timeout, objeto, funcao, condicao);
          }, 1000);
        } else {
          return;
        }
      } catch (error) {
        setTimeout(() => {
          return this.invocacaoRemotaComCondicao(repetitions - 1, timeout, objeto, funcao, condicao);
        }, 1000);
      }
    } else {
      return null;
    }
  }
}
